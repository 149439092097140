import React from 'react';
import './Card.css';

const Card=(props)=>{
    
  return (
    <div className="card-container">
      <br></br>
      <div className="card">   
        <div className="front">
          <div className="eng">{props.card.q}</div>
        </div>
        
      </div>
    </div>
)};


export default Card;


//{props.card.han}
/*<div className="back">
          <div className="pin">BBB</div>
          <div className="pin">CCC</div>
        </div>*/