import React from 'react';
import axios from 'axios';

import Card from './Card/Card';
//import { thisTypeAnnotation } from '@babel/types';

export default class App extends React.Component {
  
  constructor(props){
    super(props);

    this.state = {
      cards:[
        {id:1,q:'og'},
        {id:2,q:'i'},
        {id:3,q:'det'},
        {id:4,q:'på'},
        {id:5,q:'som'},
        {id:6,q:'er'},
        {id:7,q:'en'},
        {id:8,q:'til'},
        {id:9,q:'å'},
        {id:10,q:'han'},
        {id:11,q:'av'},
        {id:12,q:'for'},
        {id:13,q:'med'},
        {id:14,q:'at'},
        {id:15,q:'var'},
        {id:16,q:'de'},
        {id:17,q:'ikke'},
        {id:18,q:'den'},
        {id:19,q:'har'},
        {id:20,q:'jeg'},
        {id:21,q:'om'},
        {id:22,q:'et'},
        {id:23,q:'men'},
        {id:24,q:'så'},
        {id:25,q:'seg'},
        {id:26,q:'hun'},
        {id:27,q:'hadde'},
        {id:28,q:'fra'},
        {id:29,q:'vi'},
        {id:30,q:'du'},
        {id:31,q:'kan'},
        {id:32,q:'da'},
        {id:33,q:'ble'},
        {id:34,q:'ut'},
        {id:35,q:'skal'},
        {id:36,q:'vil'},
        {id:37,q:'ham'},
        {id:38,q:'etter'},
        {id:39,q:'over'},
        {id:40,q:'ved'},
        {id:41,q:'også'},
        {id:42,q:'bare'},
        {id:43,q:'eller'},
        {id:44,q:'sa'},
        {id:45,q:'nå'},
        {id:46,q:'dette'},
        {id:47,q:'noe'},
        {id:48,q:'være'},
        {id:49,q:'meg'},
        {id:50,q:'mot'},
        {id:51,q:'opp'},
        {id:52,q:'der'},
        {id:53,q:'når'},
        {id:54,q:'inn'},
        {id:55,q:'dem'},
        {id:56,q:'kunne'},
        {id:57,q:'andre'},
        {id:58,q:'blir'},
        {id:59,q:'alle'},
        {id:60,q:'noen'},
        {id:61,q:'sin'},
        {id:62,q:'ha'},
        {id:63,q:'år'},
        {id:64,q:'henne'},
        {id:65,q:'må'},
        {id:66,q:'selv'},
        {id:67,q:'sier'},
        {id:68,q:'få'},
        {id:69,q:'kom'},
        {id:70,q:'denne'},
        {id:71,q:'enn'},
        {id:72,q:'to'},
        {id:73,q:'hans'},
        {id:74,q:'bli'},
        {id:75,q:'ville'},
        {id:76,q:'før'},
        {id:77,q:'vært'},
        {id:78,q:'skulle'},
        {id:79,q:'går'},
        {id:80,q:'her'},
        {id:81,q:'slik'},
        {id:82,q:'gikk'},
        {id:83,q:'mer'},
        {id:84,q:'hva'},
        {id:85,q:'igjen'},
        {id:86,q:'fikk'},
        {id:87,q:'man'},
        {id:88,q:'alt'},
        {id:89,q:'mange'},
        {id:90,q:'tenkt'},
        {id:91,q:'ingen'},
        {id:92,q:'får'},
        {id:93,q:'oss'},
        {id:94,q:'hvor'},
        {id:95,q:'under'},
        {id:96,q:'siden'},
        {id:97,q:'hele'},
        {id:98,q:'dag'},
        {id:99,q:'gang'},
        {id:100,q:'sammen'},
        {id:101,q:'ned'},
        {id:102,q:'kommer'},
        {id:103,q:'sine'},
        {id:104,q:'deg'},
        {id:105,q:'se'},
        {id:106,q:'første'},
        {id:107,q:'godt'},
        {id:108,q:'mellom'},
        {id:109,q:'måtte'},
        {id:110,q:'gå'},
        {id:111,q:'helt'},
        {id:112,q:'litt'},
        {id:113,q:'nok'},
        {id:114,q:'store'},
        {id:115,q:'aldri'},
        {id:116,q:'ta'},
        {id:117,q:'tenkt'},
        {id:118,q:'uten'},
        {id:119,q:'dårlig'},
        {id:120,q:'kanskje'},
        {id:121,q:'blitt'},
        {id:122,q:'ser'},
        {id:123,q:'hvis'},
        {id:124,q:'bergen'},
        {id:125,q:'sitt'},
        {id:126,q:'jo'},
        {id:127,q:'vel'},
        {id:128,q:'si'},
        {id:129,q:'vet'},
        {id:130,q:'hennes'},
        {id:131,q:'min'},
        {id:132,q:'tre'},
        {id:133,q:'ja'},
        {id:134,q:'samme'},
        {id:135,q:'mye'},
        {id:136,q:'nye'},
        {id:137,q:'tok'},
        {id:138,q:'gjøre'},
        {id:139,q:'disse'},
        {id:140,q:'siste'},
        {id:141,q:'tid'},
        {id:142,q:'rundt'},
        {id:143,q:'tilbake'},
        {id:144,q:'mens'},
        {id:145,q:'satt'},
        {id:146,q:'flere'},
        {id:147,q:'folk'},
        {id:148,q:'egne'},
        {id:149,q:'fordi'},
        {id:150,q:'både'},
        {id:151,q:'la'},
        {id:152,q:'gjennom'},
        {id:153,q:'fått'},
        {id:154,q:'like'},
        {id:155,q:'nei'},
        {id:156,q:'annet'},
        {id:157,q:'komme'},
        {id:158,q:'kroner'},
        {id:159,q:'gjorde'},
        {id:160,q:'hvordan'},
        {id:161,q:'døren'},
        {id:162,q:'norge'},
        {id:163,q:'norske'},
        {id:164,q:'gjør'},
        {id:165,q:'oslo'},
        {id:166,q:'står'},
        {id:167,q:'stor'},
        {id:168,q:'gamle'},
        {id:169,q:'langt'},
        {id:170,q:'annen'},
        {id:171,q:'sett'},
        {id:172,q:'først'},
        {id:173,q:'mener'},
        {id:174,q:'hver'},
        {id:175,q:'barn'},
        {id:176,q:'rett'},
        {id:177,q:'ny'},
        {id:178,q:'tatt'},
        {id:179,q:'derfor'},
        {id:180,q:'fram'},
        {id:181,q:'hos'},
        {id:182,q:'heller'},
        {id:183,q:'lenge'},
        {id:184,q:'alltid'},
        {id:185,q:'tror'},
        {id:186,q:'nesten'},
        {id:187,q:'mann'},
        {id:188,q:'gi'},
        {id:189,q:'god'},
        {id:190,q:'lå'},
        {id:191,q:'blant'},
        {id:192,q:'norsk'},
        {id:193,q:'gjort'},
        {id:194,q:'visste'},
        {id:195,q:'bak'},
        {id:196,q:'tar'},
        {id:197,q:'liv'},
        {id:198,q:'mennesker'},
        {id:199,q:'frem'},
        {id:200,q:'bort'},
        {id:201,q:'ein'},
        {id:202,q:'verden'},
        {id:203,q:'deres'},
        {id:204,q:'ikkje'},
        {id:205,q:'nærmere'},
        {id:206,q:'tiden'},
        {id:207,q:'del'},
        {id:208,q:'vår'},
        {id:209,q:'mest'},
        {id:210,q:'eneste'},
        {id:211,q:'likevel'},
        {id:212,q:'hatt'},
        {id:213,q:'dei'},
        {id:214,q:'tidligere'},
        {id:215,q:'fire'},
        {id:216,q:'liten'},
        {id:217,q:'hvorfor'},
        {id:218,q:'tenkte'},
        {id:219,q:'hverandre'},
        {id:220,q:'holdt'},
        {id:221,q:'bedre'},
        {id:222,q:'meget'},
        {id:223,q:'ting'},
        {id:224,q:'lite'},
        {id:225,q:'enkelte'},
        {id:226,q:'stod'},
        {id:227,q:'ei'},
        {id:228,q:'hvert'},
        {id:229,q:'begynte'},
        {id:230,q:'gir'},
        {id:231,q:'ligger'},
        {id:232,q:'grunn'},
        {id:233,q:'dere'},
        {id:234,q:'livet'},
        {id:235,q:'nettopp'},
        {id:236,q:'sagt'},
        {id:237,q:'land'},
        {id:238,q:'kampen'},
        {id:239,q:'kommet'},
        {id:240,q:'skolen'},
        {id:241,q:'neste'},
        {id:242,q:'far'},
        {id:243,q:'bruke'},
        {id:244,q:'egen'},
        {id:245,q:'side'},
        {id:246,q:'gått'},
        {id:247,q:'mor'},
        {id:248,q:'ute'},
        {id:249,q:'videre'},
        {id:250,q:'eget'},
        {id:251,q:'millioner'},
        {id:252,q:'prosent'},
        {id:253,q:'svarte'},
        {id:254,q:'sto'},
        {id:255,q:'begge'},
        {id:256,q:'allerede'},
        {id:257,q:'inne'},
        {id:258,q:'finne'},
        {id:259,q:'enda'},
        {id:260,q:'hjem'},
        {id:261,q:'foran'},
        {id:262,q:'måte'},
        {id:263,q:'sterkt'},
        {id:264,q:'mannen'},
        {id:265,q:'dagen'},
        {id:266,q:'hodet'},
        {id:267,q:'saken'},
        {id:268,q:'ganger'},
        {id:269,q:'kjente'},
        {id:270,q:'stort'},
        {id:271,q:'blev'},
        {id:272,q:'mindre'},
        {id:273,q:'helst'},
        {id:274,q:'landet'},
        {id:275,q:'byen'},
        {id:276,q:'plass'},
        {id:277,q:'kveld'},
        {id:278,q:'ord'},
        {id:279,q:'øynene'},
        {id:280,q:'fem'},
        {id:281,q:'større'},
        {id:282,q:'gode'},
        {id:283,q:'nu'},
        {id:284,q:'synes'},
        {id:285,q:'beste'},
        {id:286,q:'kvinner'},
        {id:287,q:'ett'},
        {id:288,q:'satte'},
        {id:289,q:'hvem'},
        {id:290,q:'all'},
        {id:291,q:'tillegg'},
        {id:292,q:'klart'},
        {id:293,q:'holde'},
        {id:294,q:'ofte'},
        {id:295,q:'stille'},
        {id:296,q:'spurte'},
        {id:297,q:'lenger'},
        {id:298,q:'sted'},
        {id:299,q:'dager'},
        {id:300,q:'mulig'},
        {id:301,q:'utenfor'},
        {id:302,q:'små'},
        {id:303,q:'frå'},
        {id:304,q:'nytt'},
        {id:305,q:'slike'},
        {id:306,q:'viser'},
        {id:307,q:'stedet'},
        {id:308,q:'mig'},
        {id:309,q:'kjenner'},
        {id:310,q:'samtidig'},
        {id:311,q:'senere'},
        {id:312,q:'særlig'},
        {id:313,q:'våre'},
        {id:314,q:'akkurat'},
        {id:315,q:'menn'},
        {id:316,q:'hørte'},
        {id:317,q:'tenkt'},  
        {id:318,q:'arbeidet'},
        {id:319,q:'altså'},
        {id:320,q:'par'},
        {id:321,q:'din'},
        {id:322,q:'unge'},
        {id:323,q:'vant'},
        {id:324,q:'borte'},
        {id:325,q:'plutselig'},
        {id:326,q:'fant'},
        {id:327,q:'fast'},
        {id:328,q:'kunde'},
        {id:329,q:'snart'},
        {id:330,q:'svært'},
        {id:331,q:'fall'},
        {id:332,q:'vei'},
        {id:333,q:'bergens'},
        {id:334,q:'dessuten'},
        {id:335,q:'forhold'},
        {id:336,q:'gjerne'},
        {id:337,q:'snakket'},
        {id:338,q:'foto'},
        {id:339,q:'finner'},
        {id:340,q:'snakke'},
        {id:341,q:'bør'},
        {id:342,q:'dersom'},
        {id:343,q:'imidlertid'},
        {id:344,q:'lett'},
        {id:345,q:'tenke'},
        {id:346,q:'gud'},
        {id:347,q:'tro'},
        {id:348,q:'tenker'},
        {id:349,q:'jan'},
        {id:350,q:'gitt'},
        {id:351,q:'penger'},
        {id:352,q:'egentlig'},
        {id:353,q:'mitt'},
        {id:354,q:'ønsker'},
        {id:355,q:'ansiktet'},
        {id:356,q:'kl'},
        {id:357,q:'dermed'},
        {id:358,q:'seks'},
        {id:359,q:'slo'},
        {id:360,q:'betyr'},
        {id:361,q:'politiet'},
        {id:362,q:'faren'},
        {id:363,q:'eit'},
        {id:364,q:'bra'},
        {id:365,q:'holder'},
        {id:366,q:'sitter'},
        {id:367,q:'sikkert'},
        {id:368,q:'vite'},
        {id:369,q:'full'},
        {id:370,q:'lille'},
        {id:371,q:'moren'},
        {id:372,q:'glad'},
        {id:373,q:'fleste'},
        {id:374,q:'slutt'},
        {id:375,q:'ene'},
        {id:376,q:'mine'},
        {id:377,q:'gjelder'},
        {id:378,q:'lagt'},
        {id:379,q:'virkelig'},
        {id:380,q:'laget'},
        {id:381,q:'alene'},
        {id:382,q:'ennå'},
        {id:383,q:'lang'},
        {id:384,q:'ganske'},
        {id:385,q:'johan'},
        {id:386,q:'omkring'},
        {id:387,q:'hjemme'},
        {id:388,q:'vårt'},
        {id:389,q:'vanskelig'},
        {id:390,q:'arne'},
        {id:391,q:'gammel'},
        {id:392,q:'skulde'},
        {id:393,q:'tidende'},
        {id:394,q:'riktig'},
        {id:395,q:'huset'},
        {id:396,q:'følte'},
        {id:397,q:'møte'},
        {id:398,q:'lørdag'},
        {id:399,q:'klar'},
        {id:400,q:'per'},
        {id:401,q:'kort'},
        {id:402,q:'viktig'},
        {id:403,q:'ellers'},
        {id:404,q:'minst'},
        {id:405,q:'fortsatt'},
        {id:406,q:'erik'},
        {id:407,q:'veien'},
        {id:408,q:'seier'},
        {id:409,q:'mål'},
        {id:410,q:'kjent'},
        {id:411,q:'slags'},
        {id:412,q:'frode'},
        {id:413,q:'trenger'},
        {id:414,q:'vise'},
        {id:415,q:'stund'},
        {id:416,q:'arbeid'},
        {id:417,q:'finnes'},
        {id:418,q:'ingenting'},
        {id:419,q:'lange'},
        {id:420,q:'gangen'},
        {id:421,q:'stå'},
        {id:422,q:'slikt'},
        {id:423,q:'rekke'},
        {id:424,q:'redd'},
        {id:425,q:'høre'},
        {id:426,q:'vilde'},
        {id:427,q:'ga'},
        {id:428,q:'ti'},
        {id:429,q:'forteller'},
        {id:430,q:'overfor'},
        {id:431,q:'stadig'},
        {id:432,q:'burde'},
        {id:433,q:'visst'},
        {id:434,q:'syntes'},
        {id:435,q:'fjor'},
        {id:436,q:'sette'},
        {id:437,q:'funnet'},
        {id:438,q:'hjelp'},
        {id:439,q:'største'},
        {id:440,q:'løpet'},
        {id:441,q:'meter'},
        {id:442,q:'norges'},
        {id:443,q:'hånden'},
        {id:444,q:'spørsmål'},
        {id:445,q:'menneske'},
        {id:446,q:'mente'},
        {id:447,q:'søndag'},
        {id:448,q:'måten'},
        {id:449,q:'følge'},
        {id:450,q:'fremdeles'},
        {id:451,q:'imot'},
        {id:452,q:'oppe'},
        {id:453,q:'hus'},
        {id:454,q:'kvinne'},
        {id:455,q:'ventet'},
        {id:456,q:'reiste'},
        {id:457,q:'hendene'},
        {id:458,q:'trodde'},
        {id:459,q:'usa'},
        {id:460,q:'legger'},
        {id:461,q:'viste'},
        {id:462,q:'regjeringen'},
        {id:463,q:'eg'},
        {id:464,q:'årene'},
        {id:465,q:'eksempel'}
      ],
      currentCard: false,
      gameStatus:'notStarted',
      cardCount:0,
      endTime:false,
      results:[]
    }
  }



  startGame=(e,min)=>{
    e.stopPropagation();
    let endTime ;
    if(min>0){
      endTime = this.getNow() + (60 * min);
    }else{
      endTime = this.getNow() + 8;
    }
    
    let name = document.getElementById("name").value.toLowerCase()
    this.setState({gameStatus:'running', currentCard:this.getRandomCard(), currentTime:this.getMicroNow(), cardCount: 0, startTime:this.getNow(), endTime:endTime, user:name, results:[] } );
  }

  getRandomCard=()=>{
    return this.state.cards[Math.floor(Math.random() * this.state.cards.length) ];  
  }

  getProblemCards=()=>{
    let result = this.state.results;
    
    result.sort(function(a, b) {
      return parseFloat( a.time / a.cnt ) - parseFloat(b.time / b.cnt);
    });

    let max = result.length;
    let min= (max/2); 
    
    let random = Math.round(Math.floor(Math.random() * (+max - +min)) + +min);

    return this.state.cards[random];
  }

  nextCard=()=>{
    let timeUsed = this.getMicroNow()-this.state.currentTime;
    let res = this.state.results;
    let found = false;
    
    if(res.length>0){
      let curId = this.state.currentCard.id;
      for(let i = 0 ; i<res.length; i++){
        if(res[i].id === curId){
          found=true;
          res[i].cnt++;
          res[i].time += timeUsed;
        }
      }
    }

    if (found===false){
      res.push({id:this.state.currentCard.id,q:this.state.currentCard.q, cnt:1,time:this.getMicroNow()-this.state.currentTime});
    }

    if(this.state.endTime>this.getNow()){
      let halfWay = (this.state.endTime - this.state.startTime)/2;
      let nextCard = false;
      if(this.getNow()< (this.state.startTime+halfWay)){
        nextCard = this.getRandomCard();
      }else{
        nextCard = this.getProblemCards();
      }

      this.setState({currentCard:nextCard, currentTime:this.getMicroNow(), cardCount:(this.state.cardCount+1),results:res } );
    }else{

      axios.post('http://akiviski.no/api/words/save', {
          res:res,
          user:this.state.user
      }).then(res=> {
          console.log(res);
      });
      



      this.setState({gameStatus:'done',results:res} );
    }
  }

  getNow(){
    return Math.floor(new Date().getTime() / 1000);
  }

  getMicroNow(){
    return Math.floor(new Date().getTime() );
  }

  render(){
    if(this.state.gameStatus==="running"){

      let timeLeft = this.state.endTime - this.getNow();

      return (
        <div className="AppSecret noselect" onClick={(e)=>this.nextCard()}>
          <div className="cardRow">
            <div className="meta">Ord:{this.state.cardCount} - Tid:{timeLeft}</div>
            <Card card={this.state.currentCard}/>
          </div>
        </div>
      );

    }else if(this.state.gameStatus==="done"){

      let words ="";
      let result = this.state.results;
    
      result.sort(function(a, b) {
        return parseFloat( a.time / a.cnt ) - parseFloat(b.time / b.cnt);
      });
      
      for(let i = 0 ; i < result.length; i++ ){
        words += (i>0?", ":"") + result[i].q;
      }

      let name = this.state.user.charAt(0).toUpperCase() + this.state.user.slice(1)

      return (
        <div className="App">
            <h1>Nå er du ferdig {name}!</h1>
            Du klarte {this.state.cardCount} ord.<br></br><br></br>

            { words } 
            <hr></hr>

            Ditt navn:&nbsp;&nbsp;
          <input type="text" name="name" id="name" />
          <br></br> <br></br>

          <div style={{width:'300px',margin:'auto'}}>        
            <div onClick={(e)=>this.startGame(e, 10)} className="btn">10 min</div>
            <div onClick={(e)=>this.startGame(e, 15)} className="btn">15 min</div>
            <div onClick={(e)=>this.startGame(e, 20)} className="btn">20 min</div>
          </div>
          <div style={{clear:'both'}}></div>
        </div>
      )

    }else{     
      return (
        <div className="App" > 
          <i>høyfrekvente ord</i>
          <h2>Velkommen til lese-trening</h2>
          <br></br>
          Ditt navn:&nbsp;&nbsp;
          <input type="text" name="name" id="name" />
          <br></br> <br></br>

          <div style={{width:'300px',margin:'auto'}}>
            <div onClick={(e)=>this.startGame(e, 10)} className="btn">10 min</div>
            <div onClick={(e)=>this.startGame(e, 15)} className="btn">15 min</div>
            <div onClick={(e)=>this.startGame(e, 20)} className="btn">20 min</div>
          </div>
          <div style={{clear:'both'}}></div>
        </div>
      )
    }
  }

}

